import React, { useEffect, useRef, useState } from 'react';
import IconClose from '../IconClose/IconClose';
import VoiceSearch from './VoiceSearch/VoiceSearch';
import css from './AlgoliaKeywordSearch.module.css';

export default function KeywordInput(props) {
  const {
    onSearchChange,
    algoliaQueryInProgress,
    setIsSearchFocused,
    isSearchFocused,
    clearKeywordsClass,
    micClass,
    keyword,
    setKeyword,
    searchBoxRef,
    searchInputText,
    onFocus,
    isDesktopLayout,
    autoFocus,
    blockAutoScroll,
    showMap
  } = props;
  const [strokTimer, setStrokTimer] = useState(null);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    let timer;
    if (!algoliaQueryInProgress) {
      if (strokTimer) {
        clearTimeout(strokTimer);
      };

      timer = setTimeout(() => {
        onSearchChange(keyword);
        // Scroll the container into view
        if (typeof window != 'undefined' && window.scrollTo && !isDesktopLayout && !blockAutoScroll && showMap) {
          window.scrollTo({
            top: 250, // Desired vertical position
            behavior: 'smooth',
          });
        }
        if (searchBoxRef && searchBoxRef.current) {
          searchBoxRef.current.scrollTo(0, 0);
        }
      }, 500);

      setStrokTimer(timer);
    };


    return () => clearTimeout(timer);
  }, [keyword]);

  const handleChange = text => {
    if (!isSearchFocused) {
      setIsSearchFocused(true)
    }
    setKeyword(text);
  };

  const handleFocus = () => {
    if (isInitial) {
      setIsInitial(false)
    } else {
      setIsSearchFocused(true);
    }

    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  return (
    <div>
      <div className={css.keywordSearchWrapper}>
        <input
          className={css.keywordSearch}
          value={keyword}
          onChange={e => handleChange(e.target.value)}
          type="text"
          placeholder={searchInputText || 'Search by keyword'}
          onFocus={handleFocus}
          autoFocus={autoFocus}
        />
        {!!keyword ? (
          <button
            className={clearKeywordsClass}
            type="button"
            onClick={() => {
              setKeyword('');
              onSearchChange('');
              if (searchBoxRef && searchBoxRef.current) {
                searchBoxRef.current.scrollTo(0, 0);
              }
            }}
          >
            <IconClose size="small" />
          </button>
        ) : null}
        <VoiceSearch className={micClass} onVoiceSearch={transcript => handleChange(transcript)} />
      </div>
    </div>
  )
}
