
import React, { useState, useEffect } from "react";
import css from './VoiceSearch.module.css';
import classNames from "classnames";

function VoiceSearch({ onVoiceSearch, className }) {
    const SpeechRecognition = typeof window !== 'undefined' ? window.SpeechRecognition || window.webkitSpeechRecognition : null;

    const [isListening, setIsListening] = useState(false);
    const [note, setNote] = useState("");

    useEffect(() => {
        if (!SpeechRecognition) {
            console.warn("Browser does not support Speech Recognition");
            return;
        }

        const mic = new SpeechRecognition();
        mic.continuous = true;
        mic.interimResults = true;
        mic.lang = "en-US";

        const handleListen = () => {
            if (isListening) {
                mic.start();
                mic.onend = () => {
                    console.log("Mic restarted");
                    mic.start();
                };
            } else {
                mic.stop();
                mic.onend = () => {
                    console.log("Mic stopped");
                };
            }

            mic.onstart = () => {
                console.log("Mic is on");
            };

            mic.onresult = (event) => {
                const transcript = Array.from(event.results)
                    .map(result => result[0])
                    .map(result => result.transcript)
                    .join("");
                console.log(transcript, 'trans')
                onVoiceSearch(transcript);
                setNote(transcript);
            };

            mic.onerror = (event) => {
                console.error("Mic error:", event.error);
            };
        };

        handleListen();

        return () => {
            mic.stop();
            mic.onend = null;
            mic.onstart = null;
            mic.onresult = null;
            mic.onerror = null;
        };
    }, [isListening, SpeechRecognition]);

    const handleButtonClick = () => {
        setIsListening(prevState => !prevState);
    };

    if (!SpeechRecognition) {
        return null;
    }

    return (
        <div className={classNames(className, css.container)}>
            <button className={css.btn} type="button" onClick={handleButtonClick}>
                {isListening ? (
                    <div className={css.listeningContainer}>
                        <div id="speech" className={css.speechButton}>
                            <div className={css.pulseRing}></div>
                            <svg fill="#000000" width="24px" height="24px" viewBox="-0.105 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg"><path d="M0.252 0.504a0.144 0.144 0 0 0 0.144 -0.144V0.144a0.144 0.144 0 1 0 -0.288 0V0.36a0.144 0.144 0 0 0 0.144 0.144" /><path d="M0.504 0.36V0.288a0.036 0.036 0 1 0 -0.072 0V0.36a0.18 0.18 0 1 1 -0.36 0V0.288a0.036 0.036 0 1 0 -0.072 0V0.36a0.252 0.252 0 0 0 0.215 0.249l0.001 0V0.648H0.108a0.036 0.036 0 1 0 0 0.072h0.288a0.036 0.036 0 1 0 0 -0.072H0.288v-0.039c0.123 -0.018 0.216 -0.123 0.216 -0.249z" /></svg>
                        </div>
                    </div>
                ) : (
                    <div className={css.listeningContainer}>
                        <div id="speech" className={classNames(css.speechButton, css.speechButtonBefore)}>
                            <span className={css.speechMicBefore}>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 17.5C13.9583 17.5 13.0729 17.1354 12.3438 16.4062C11.6146 15.6771 11.25 14.7917 11.25 13.75V6.25C11.25 5.20833 11.6146 4.32292 12.3438 3.59375C13.0729 2.86458 13.9583 2.5 15 2.5C16.0417 2.5 16.9271 2.86458 17.6562 3.59375C18.3854 4.32292 18.75 5.20833 18.75 6.25V13.75C18.75 14.7917 18.3854 15.6771 17.6562 16.4062C16.9271 17.1354 16.0417 17.5 15 17.5ZM13.75 26.25V22.4062C11.5833 22.1146 9.79167 21.1458 8.375 19.5C6.95833 17.8542 6.25 15.9375 6.25 13.75H8.75C8.75 15.4792 9.35958 16.9529 10.5788 18.1712C11.7971 19.3904 13.2708 20 15 20C16.7292 20 18.2033 19.3904 19.4225 18.1712C20.6408 16.9529 21.25 15.4792 21.25 13.75H23.75C23.75 15.9375 23.0417 17.8542 21.625 19.5C20.2083 21.1458 18.4167 22.1146 16.25 22.4062V26.25H13.75Z" fill="#2B3740" />
                                </svg>

                            </span>
                        </div>
                    </div>

                )}
            </button>
        </div>
    );
}

export default VoiceSearch;
