import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, LINE_ITEM_HOUR, propTypes, LINE_ITEM_MINUTE } from '../../util/types';
import { subtractTime } from '../../util/dates';

import css from './OrderBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;
  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
        weekday: 'short',
      }
      : {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSectionLeft}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingStart" />
          </div>
          <div className={css.itemLabelDate}>
             <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} /><span>, </span>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>

        <div className={css.bookingPeriodSectionRight}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingEnd" />
          </div>
          <div className={css.itemLabelDate}>
          <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} /><span>, </span>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, code, timeZone, transaction } = props;

  // Previously when we were working with the single listing booking,
  // we were storing the dates to protected data. We need to consider
  // those booking also.
  const transactionProtectedData = transaction?.attributes?.protectedData;
  const { bookingStart: oldBookingS, bookingEnd: oldBookingE } = transactionProtectedData || {};

  const itemsProtectedData = transaction?.attributes?.protectedData?.itemsProtectedData || "[]";
  const currentItemProtectedData = JSON.parse(itemsProtectedData).find(i => i.code === code) || JSON.parse(itemsProtectedData)[0];
  const { bookingStart, bookingEnd } = currentItemProtectedData?.bookingDates || {};
  const hasDates = (bookingStart && bookingEnd) || (oldBookingS && oldBookingE);

  // console.log({ currentItemProtectedData, code }, '&&& &&& => item d');

  const newCode = code?.includes(':');
  const updatedCode = !newCode ? code : `line-item/${code.split('-').reverse()[0]}`

  if (!booking && !hasDates) {
    return null;
  }
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  let localStartDate;
  let localEndDateRaw;
  if (booking) {
    let { start, end, displayStart, displayEnd } = booking.attributes;
    localStartDate = displayStart || start;
    localEndDateRaw = displayEnd || end;
  } else {
    localStartDate = bookingStart || oldBookingS;
    localEndDateRaw = bookingEnd || oldBookingE;
  }

  const isNightly = updatedCode === LINE_ITEM_NIGHT;
  const isHour = updatedCode === LINE_ITEM_HOUR;
  const isMinute = updatedCode === LINE_ITEM_MINUTE;
  const endDay = isNightly || isHour || isMinute ? localEndDateRaw : subtractTime(localEndDateRaw, 1, 'days');

  const dateType = isHour || isMinute ? 'datetime' : 'date';

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          endDate={endDay}
          dateType={dateType}
          timeZone={timeZone}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { booking: null, dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
