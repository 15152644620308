// SkeletonCard.jsx
import React from 'react';
import './SkeletonCard.css';
import classNames from 'classnames';

const SkeletonCard = ({className}) => {
  return (
    <div className={classNames(className, "skeleton-card")}>
      <div className="skeleton-image"></div>
      <div className="skeleton-content">
        <div className="skeleton-title"></div>
        <div className="skeleton-subtitle"></div>
        <div className="skeleton-text"></div>
        <div className="skeleton-rating"></div>
      </div>
    </div>
  );
};

export default SkeletonCard;
