import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import countryList from 'country-list';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import IconClose from '../IconClose/IconClose';
import IconCard from '../SavedCardDetails/IconCard/IconCard';
import { H4, H5 } from '../Heading/Heading';
import { createSlug } from '../../util/urlHelpers';
import { convertPrice, formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import { types } from '../../util/sdkLoader';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';

import { displayPrice } from '../../util/configHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { FormattedMessage } from '../../util/reactIntl';
import { IoIosStarHalf } from 'react-icons/io';
import { IoStar } from 'react-icons/io5';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import { PrimaryButton } from '../Button/Button';
import SearchTeamForm from './SearchTeam/SearchTeam';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import KeywordInput from './KeywordInput';
import SkeletonCard from '../SkeletonCard/SkeletonCard';
import css from './AlgoliaKeywordSearch.module.css';


const { UUID } = types;

const MODAL_BREAKPOINT = 768;
const isWindowDefined = typeof window !== 'undefined';
const isDesktopLayout = isWindowDefined && window.innerWidth > MODAL_BREAKPOINT;

const recentSearchesKey = 'recent-searches';


const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const PriceMaybe = props => {
  const {
    price,
    listingType,
    defaultPriceKey,
    transactionProcessAlias,
    config,
    intl,
    selectedCurrency,
  } = props;

  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(
    conf => conf.listingType === listingType
  );
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(
    price,
    selectedCurrency,
    intl
  );

  return (
    <div className={css.price}>
      <span title={priceTitle}>{formattedPrice} </span>
      {isBookable ? (
        <span className={css.perUnit}>
          <FormattedMessage
            id="ListingCard.perUnit"
            values={{ defaultPriceKey: defaultPriceKey || 'perDayPrice' }}
          />
        </span>
      ) : null}
    </div>
  );
};

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text; // Return the original text if it's within or equal to the maxLength
  } else {
    return text.slice(0, maxLength) + '...'; // Truncate the text and add ellipsis
  }
}

// Search Inputs
const RecentSearchItem = props => {
  const { label, id, refine, setKeyword, setRecentSearces } = props;
  const handleClick = id => {
    refine(id);
    setKeyword(id);
  };

  const handleDelete = () => {
    const recentSearchItems =
      typeof window != 'undefined' && window.localStorage
        ? window.localStorage.getItem(recentSearchesKey)
        : null;
    const parsedItems = recentSearchItems ? JSON.parse(recentSearchItems) : [];
    if (parsedItems.length) {
      const filteredItems = parsedItems.filter(i => i.id !== id);
      window.localStorage.setItem(
        recentSearchesKey,
        JSON.stringify(filteredItems)
      );
      setRecentSearces(filteredItems);
    }
  };

  return (
    <li className={css.recentSearchItem}>
      <span className={css.recentUse} onClick={() => handleClick(id)}>
        <IconCard brand="recent" />
      </span>
      <span className={css.recentLabel}>{label}</span>
      <span className={css.recentDelete} onClick={handleDelete}>
        <IconCard brand="delete" />
      </span>
    </li>
  );
};

// Rental Hit
const RentalHit = props => {
  const {
    rental = {},
    intl,
    onClick,
    history,
    routes,
    exchangeRates,
    currentUserCurrency,
    config,
    queryID,
    onActivateListing,
    allowCopyListings
  } = props;

  const id = rental.objectID;
  const {
    title = '',
    price,
    user: { displayName, publicData: uPublicData },
    publicData,
    additional,
  } = rental;

  const { storeName, rating } = uPublicData || {};

  const {
    category,
    city,
    listingImages = [],
    prices,
    listingType,
    transactionProcessAlias,
    defaultPriceKey,
  } = publicData || {};

  const aspectWidth = 2;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';

  const slug = createSlug(title);
  const convertedPrice = !!price
    ? convertPrice(
      exchangeRates,
      price.amount,
      price.currency,
      currentUserCurrency
    )
    : null;

  const firstImage = listingImages[0];
  const firstImageMaybe =
    !!firstImage && firstImage.secure_url ? (
      <ResponsiveImage
        renderCloudinaryImage={{
          secure_url: firstImage.secure_url,
          alt: title,
          variantsToRender: [
            'listing-card-4x',
            'listing-card-2x',
            'listing-card',
          ],
          defaultVariant: 'listing-card-4x',
        }}
      />) : (
      <div className={css.noImageWrapper}>
        <svg
          color="#333"
          width="100px"
          height="100px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
      </div>
    );

  const handleClick = () => {
    onClick();
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routes,
        { id, slug },
        { city, queryID, allowCopy: allowCopyListings, category }
      )
    );
  };

  const setActivePropsMaybe = onActivateListing
    ? {
      onMouseEnter: () => onActivateListing(new UUID(id)),
      onMouseLeave: () => onActivateListing(null),
    }
    : {};

  return (
    <div className={css.searchRentalHit} onClick={handleClick}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {firstImageMaybe}
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>{truncateText(title, 30)}</div>
        </div>
        <div className={css.userRating}>

          <Ratings rating={rating} />
        </div>

        <PriceMaybe
          price={convertedPrice}
          listingType={listingType}
          transactionProcessAlias={transactionProcessAlias}
          defaultPriceKey={defaultPriceKey}
          config={config}
          intl={intl}
          selectedCurrency={currentUserCurrency}
        />
        <div className={css.morePrices}>See more Options</div>
      </div>
      {/* {additional ? <span className={css.additional}>Additional</span> : null} */}
    </div>
  );
};

// Rental Hits
const SearchRentalHits = React.memo(props => {
  const { currentUser, queryID, listings, intl, algoliaQueryInProgress } = props;
  const sectionTitle = intl.formatMessage({ id: "AlgoliaKeywordSearch.rentalsTitle" });
  const userTokenMaybe =
    currentUser && currentUser.id ? { userToken: currentUser.id.uuid } : {};

  return (
    <div className={css.searchHits} id="hits">
      <H5 className={css.searchHitsTitle}>{sectionTitle}</H5>
      <div className={css.hitsContianer}>
        {algoliaQueryInProgress
          ? Array.from({ length: 10 }).map((_, index) => (
            <SkeletonCard className={css.keywordSearchCard} key={index} />
          )) : listings.map(i => (
            <RentalHit
              key={i.objectID}
              rental={i}
              onClick={() => {
                // sendEvent('click', i, PRODUCT_CLICKED_EVENT, {
                //   objectIds: [i.objectID],
                //   ...userTokenMaybe,
                // });
              }}
              queryID={queryID}
              {...props}
            />
          ))}
      </div>
    </div>
  );
});

const Ratings = props => {
  const { rating: ratingPeruser = 5, reviews: totalRatings } = props;
  const starArray = [1, 2, 3, 4, 5];

  const filledStars = Math.floor(ratingPeruser);
  const hasHalfStar = ratingPeruser % 1 !== 0;

  return (
    <div className={css.ratingContent}>
      {starArray.map(star => {
        return star === filledStars + 1 && hasHalfStar ? (
          <IoIosStarHalf className={css.starIconGold} key={star} />
        ) : (
          <IoStar
            className={
              star <= filledStars ? css.starIconGold : css.starIconGrey
            }
            key={star}
          />
        );
      })}
    </div>
  );
};

// Store Hit
const StoreHit = props => {
  const { store = {}, onClick, history, routes } = props;

  const id = store.objectID;
  const { displayName: userName, profileImage, publicData, additional } = store;

  const { city, country: countryCode, rating, reviews, storeName } = publicData || {};
  const countryName = !!countryCode?.trim()
    ? countryList.getName(countryCode)
    : null;

  const displayName = !!storeName ? storeName : userName;

  const aspectWidth = 1;
  const aspectHeight = 1;

  const cityAndCountryMaybe =
    city?.trim() && countryName?.trim() ? (
      <p className={css.location}>
        {city}, {countryName}
      </p>
    ) : city ? (
      <p className={css.location}>{city}</p>
    ) : countryName ? (
      <p className={css.location}>{countryName}</p>
    ) : null;

  const profileImageMaybe =
    !!profileImage && profileImage.secure_url ? (
      <ResponsiveImage
        renderCloudinaryImage={{
          secure_url: profileImage.secure_url,
          alt: storeName || displayName,
          variantsToRender: [
            'listing-card-4x',
            'listing-card-2x',
            'listing-card',
          ],
          defaultVariant: 'listing-card-4x',
        }}
      />) : (
      <div className={css.noImageContainer}>
        <H4 className={css.noImage}>{displayName}</H4>
      </div>
    );

  const handleStoreClick = () => {
    onClick();
    history.push(
      createResourceLocatorString(
        'ProfilePage',
        routes,
        { id },
        { city, displayName }
      )
    );
  };

  return (
    <div onClick={handleStoreClick} className={css.searchStoriesHit}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
      >
        {profileImageMaybe}
      </AspectRatioWrapper>
      <div className={css.storeInfo}>
        <h6 className={css.storeName}>{truncateText(displayName, 30)}</h6>
        {cityAndCountryMaybe}
        <div className={css.rating}>
          <Ratings rating={rating} reviews={reviews} />
        </div>
      </div>
      {/* {additional ? <span className={css.additional}>Additional</span> : null} */}
    </div>
  );
};

// Store Hits
const SearchStoreHits = React.memo(props => {
  const { routes, currentUser, history, intl, stores, algoliaQueryInProgress } = props;

  const sectionTitle = intl.formatMessage({ id: "AlgoliaKeywordSearch.storesTitle" });

  const userTokenMaybe =
    currentUser && currentUser.id ? { userToken: currentUser.id.uuid } : {};

  return (
    <div className={css.searchHits} id="hits">
      <H5 className={css.searchHitsTitle}>{sectionTitle}</H5>
      <div className={css.hitsContianer}>
        {algoliaQueryInProgress
          ? Array.from({ length: 10 }).map((_, index) => (
            <SkeletonCard className={css.keywordSearchCard} key={index} />
          )) : stores.map(i => (
            <StoreHit
              key={i.objectID}
              store={i}
              routes={routes}
              history={history}
              onClick={() => {
                // sendEvent('click', i, STORE_CLICKED_EVENT, {
                //   objectIds: [i.objectID],
                //   ...userTokenMaybe,
                // })
              }
              }
            />
          ))}
      </div>
    </div>
  );
});

const KeywordSearch = props => {
  const {
    onSearchChange,
    searchInputText,
    routes,
    history,
    currentUserCurrency,
    currentUser,
    exchangeRates,
    config,
    intl,
    clearKeywordsClass,
    micClass,
    autoFocus = false,
    onFocus,
    onActivateListing,
    hideStores,
    allowCopyListings,
    algoliaListings = [],
    algoliaStores = [],
    algoliaQueryInProgress,
    initialValue,
    blockAutoScroll,
    showMap
  } = props;

  const searchBoxRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isRecentSearches, setIsRecentSearches] = useState(false);
  const [isSearchTeamModal, setIsSearchTeamModal] = useState(false);
  const [keyword, setKeyword] = useState(initialValue.keyword);

  const recentSearchesFromLocalStorage =
    typeof window != 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem(recentSearchesKey) &&
      window.localStorage.getItem(recentSearchesKey).length
      ? JSON.parse(window.localStorage.getItem(recentSearchesKey))
      : [];
  const [recentSearches, setRecentSearces] = useState(
    recentSearchesFromLocalStorage
  );

  const handleBlurSearchInput = () => {
    setIsSearchFocused(false);
    if (!!keyword?.trim()) {
      const isOldKeyword = recentSearches?.findIndex(s => s.id === keyword);

      if (isOldKeyword === -1) {
        const limitedRecentSearches = recentSearches.splice(0, 9);
        const latestSearches = [
          { label: keyword, id: keyword },
          ...limitedRecentSearches,
        ];

        setRecentSearces(latestSearches);
        if (typeof window != 'undefined' && window.localStorage) {
          window.localStorage.setItem(
            recentSearchesKey,
            JSON.stringify(latestSearches)
          );
        }
      }
    }
  };

  const showRecentSearches = isDesktopLayout;

  return (
    <div className={css.keywordSearchContainer}>
      {/* Search Team Form */}
      <SearchTeamForm
        id="search-team-form"
        isOpen={isSearchTeamModal}
        onClose={() => setIsSearchTeamModal(false)}
        setIsSearchTeamModal={setIsSearchTeamModal}
      />
      <OutsideClickHandler
        className={css.outSideClickContainer}
        onOutsideClick={handleBlurSearchInput}
      >
        <KeywordInput
          onSearchChange={onSearchChange}
          algoliaQueryInProgress={algoliaQueryInProgress}
          setIsSearchFocused={setIsSearchFocused}
          isSearchFocused={isSearchFocused}
          clearKeywordsClass={clearKeywordsClass}
          micClass={micClass}
          keyword={keyword}
          setKeyword={setKeyword}
          searchBoxRef={searchBoxRef}
          searchInputText={searchInputText}
          onFocus={onFocus}
          isDesktopLayout={isDesktopLayout}
          autoFocus={autoFocus}
          blockAutoScroll={blockAutoScroll}
          showMap={showMap}
        />
        {/* Hide temporarily */}
        {/* <div
          className={classNames(
            css.kewordSearchContainer,
            !isSearchFocused ? css.closed : ''
          )}
          ref={searchBoxRef}
        >
          <div>
            <div className={css.getHelpContent}>
              <H5 className={css.lookingForText}><FormattedMessage id='AlgoliaKeywordSearch.lookingFor'/></H5>
             <PrimaryButton
              className={css.searchTeamButton}
              type="button"
              onClick={() => setIsSearchTeamModal(true)}
              >
              <FormattedMessage id='AlgoliaKeywordSearch.searchTeam' />
             </PrimaryButton>
            </div>
            <button
              className={css.collapseKeywordSearch}
              type="button"
              onClick={handleBlurSearchInput}
            >
              <IconClose size="big" />
            </button>

            <div className={css.federatedSearchDiv}>
             
              {showRecentSearches ? <div className={css.recentSearches}>
                <div className={css.recentSearchesHeader}>
                  <h5 className={css.searchHitsTitle}><FormattedMessage id='AlgoliaKeywordSearch.recentSearch' /></h5>
                
                  {!isDesktopLayout ? <button
                    className={css.arrowButton}
                    type="button"
                    onClick={() => setIsRecentSearches(!isRecentSearches)}
                  >
                    <IconArrowHead
                      direction="down"
                      size="small"
                      rootClassName={isRecentSearches ? classNames(css.arrowIcon, css.active) : css.arrowIcon}
                    />
                  </button> : null}
                </div>
                {(isRecentSearches || isDesktopLayout) ? <ul className={css.totalItem}>
                  {recentSearches.slice(0, 10).map(r => (
                    <RecentSearchItem
                      key={r.id}
                      label={r.label}
                      id={r.id}
                      refine={onSearchChange}
                      setKeyword={setKeyword}
                      setRecentSearces={setRecentSearces}
                    />
                  ))}
                </ul> : null}
              </div> : null}
              <div className={css.imageSeacrhResult}>
               
                <SearchRentalHits
                  history={history}
                  currentUserCurrency={currentUserCurrency}
                  currentUser={currentUser}
                  exchangeRates={exchangeRates}
                  routes={routes}
                  config={config}
                  intl={intl}
                  onActivateListing={onActivateListing}
                  allowCopyListings={allowCopyListings}
                  listings={algoliaListings}
                  algoliaQueryInProgress={algoliaQueryInProgress}
                />

               
                <SearchStoreHits
                  currentUser={currentUser}
                  history={history}
                  routes={routes}
                  config={config}
                  intl={intl}
                  stores={algoliaStores}
                  algoliaQueryInProgress={algoliaQueryInProgress}
                />
              </div>
            </div>
          </div>
        </div> */}
      </OutsideClickHandler>
    </div>
  );
};

export default KeywordSearch;
