// Breadcrumb.js
import React from 'react';
import css from './Breadcrumb.module.css';
import NamedLink from '../NamedLink/NamedLink';
import { array } from 'prop-types';

const Breadcrumb = ({ items, className }) => {
    return (
        <nav aria-label="breadcrumb" className={`${css.root} ${className}`}>
            <ol className={css.breadcrumbLinks}>
                {items.map((item, index) => (
                    <li key={index} className={`${css.breadcrumbItem} ${index === items.length - 1 ? css.active : ''}`}>
                        {index === items.length - 1 ? (
                            item.label
                        ) : (
                            <NamedLink className={css.link} name={item.name} params={item.params}>{item.label}</NamedLink>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

Breadcrumb.prototype = {
    items: array.isRequired
};

export default Breadcrumb;
