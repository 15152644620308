import { getExchangeRates } from "../util/api";

const EXCHANGE_RATE_REQUEST = 'app/exchangeRate/EXCHANGE_RATE_REQUEST';
const EXCHANGE_RATE_SUCCESS = 'app/exchangeRate/EXCHANGE_RATE_SUCCESS';
const EXCHANGE_RATE_ERROR = 'app/exchangeRate/EXCHANGE_RATE_ERROR';

const initialState = {
    fetchRatesInProgress: false,
    fetchRatesError: null,
    exchangeRates: [],
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case EXCHANGE_RATE_REQUEST:
            return {
                ...state,
                fetchRatesInProgress: true,
                fetchRatesError: null
            };
        case EXCHANGE_RATE_SUCCESS:
            return {
                fetchRatesInProgress: false,
                exchangeRates: payload,
                fetchRatesError: null
            };

        case EXCHANGE_RATE_ERROR:
            return {
                ...state,
                fetchRatesInProgress: false,
                fetchRatesError: payload
            };

        default:
            return state;
    }
};

const exchangeRateRequest = () => ({ type: EXCHANGE_RATE_REQUEST });
const exchangeRateSuccess = (rates) => ({ type: EXCHANGE_RATE_SUCCESS, payload: rates });
const exchangeRateError = (error) => ({ type: EXCHANGE_RATE_ERROR, payload: error, error: true });


export const fetchExchangeRates = () => (dispatch) => {
    try {
        dispatch(exchangeRateRequest());

       return getExchangeRates()
            .then(({ data }) => {
                dispatch(exchangeRateSuccess(data));
                return data;
            })
            .catch(err => dispatch(exchangeRateError(err)));

    } catch (error) {
        dispatch(exchangeRateError(error));
    }
};
