import { fetchExchangeRates } from '../../ducks/exchangeRates.duck';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  const { exchangeRates } = getState().exchangeRate;

  if (typeof window != 'undefined' && exchangeRates && exchangeRates.length == 0) {

    dispatch(fetchExchangeRates());
  };

  return dispatch(fetchPageAssets(pageAsset, true));
};
