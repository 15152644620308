import axios from 'axios';
import { Cloudinary } from 'cloudinary-core';
import { getCloudinarySignature } from './api';

const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET;
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD;
const uploadEndpoint = process.env.REACT_APP_CLOUDINARY_UPLOAD_ENDPOINT.replace(
  '<cloudName>',
  cloudName
);
const apiKey = process.env.REACT_APP_CLOUDINARY_API_KEY;

const cloudinaryInstance = new Cloudinary({
  cloud_name: cloudName,
  secure: true,
});


export const UploadImage = async ({ file, id }) => {
  try {
    // First, get the signature and timestamp from your server
    const signatureResponse = await getCloudinarySignature({ public_id: id, transformation: 'f_webp' })

    const { signature, timestamp } = signatureResponse;

    // Prepare the FormData for the image upload
    const formData = new FormData();
    formData.append('file', file);
    formData.append('public_id', id); // optional, can let Cloudinary generate it
    formData.append('api_key', apiKey);
    formData.append('timestamp', timestamp);
    formData.append('signature', signature);
    formData.append('transformation', 'f_webp');

    // Upload to Cloudinary
    const response = await axios.post(
      uploadEndpoint,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    // Handle the successful upload response
    console.log('Image uploaded:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};


export const cloudinaryModernOptions = {
  format: 'auto',
  quality: 'auto:eco',  // Aggressive compression
  dpr: 'auto'           // Adjust resolution based on user's device
};

export function getUrlVariants(secureUrl, variant, options = cloudinaryModernOptions) {

  // Define the variants
  const variants = {
    'listing-card-6x': { transformation: 'w_2400,h_2400', height: 2400, width: 2400 },
    'listing-card-4x': { transformation: 'w_1600,h_1600', height: 1600, width: 1600 },
    'listing-card-2x': { transformation: 'w_800,h_800', height: 800, width: 800 },
    'listing-card': { transformation: 'w_400,h_400', height: 400, width: 400 },
    'landscape-crop': { transformation: 'c_crop,w_400,h_267', height: 267, width: 400 },
    'landscape-crop2x': { transformation: 'c_crop,w_800,h_533', height: 533, width: 800 },
    'landscape-crop4x': { transformation: 'c_crop,w_1600,h_1066', height: 1066, width: 1600 },
    'landscape-crop6x': { transformation: 'c_crop,w_2400,h_1602', height: 1602, width: 2400 },
    'scaled-small': { transformation: 'w_320,h_320', height: 320, width: 320 },
    'scaled-medium': { transformation: 'w_750,h_750', height: 750, width: 750 },
    'scaled-large': { transformation: 'w_1024,h_1024', height: 1024, width: 1024 },
    'scaled-xlarge': { transformation: 'w_2400,h_2400', height: 2400, width: 2400 },
    'square-small': { transformation: 'c_crop,w_240,h_240', height: 240, width: 240 },
    'square-small2x': { transformation: 'c_crop,w_480,h_480', height: 480, width: 480 },
    'facebook': { transformation: 'c_crop,w_1200,h_630', height: 630, width: 1200 },
    'twitter': { transformation: 'c_crop,w_600,h_314', height: 314, width: 600 },
    'default': { transformation: '' }
  };

  const { format = 'auto', fetchFormat = 'auto', quality = 'auto:eco', dpr = 'auto', crop = 'fit' } = options;

const splittedURL = secureUrl.split('/upload');

if (splittedURL && splittedURL.length == 2 && variants[variant]) {
  return {
    variants,
    imageUrl: `${splittedURL[0]}/upload/${variants[variant].transformation},c_${crop},f_${format},q_${quality},dpr_${dpr}/${splittedURL[1]}`
  };
}

  return { variants };
}

export function getCloudinarySecureUrl(publicId, options = {}) {
  const { 
    folder = '',                    // Optional folder where the image is stored
    transformation = '',             // Optional transformations (e.g., 'w_400,h_400,c_fill')
    quality = 'auto:eco',            // Optional quality (default to aggressive compression)
    dpr = 'auto',                    // Optional device pixel ratio setting
  } = options;

  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD;

  // Construct the base URL with Cloudinary's standard secure path
  let secureUrl = `https://res.cloudinary.com/${cloudName}/image/upload/`;

  // Add transformation string (including format auto selection and other options)
  let transformationString = '';

  // Include additional transformations if provided
  if (transformation) {
    transformationString += `,${transformation}`;
  }

  // Append the transformations to the URL
  secureUrl += `${transformationString}/`;

  // Add folder path if provided
  if (folder) {
    secureUrl += `${folder}/`;
  }

  // Add public ID (without the need to specify a format)
  secureUrl += publicId;

  return secureUrl;
}


// Utility function to get WebP image URL from Cloudinary
export const getWebPImageUrl = (publicId, options = '') => {
  return `https://res.cloudinary.com/${cloudName}/image/upload/f_webp${options ? `,${options}` : ''}/${publicId}`;
};


export default cloudinaryInstance;