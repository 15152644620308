
import { queryAlgolia } from '../../../util/api';
import { algoliaListingFacets } from '../../../config/configDefault';
import { fetchExchangeRates } from '../../../ducks/exchangeRates.duck';
import { parse } from '../../../util/urlHelpers';
import algoliasearch from 'algoliasearch';
import { facetLabelMappings } from '../../../util/constants';
import { createBoundingBox } from '../../../util/data';

// ================ Action types ================ //

export const SEARCH_MAP_SET_ACTIVE_LISTING =
  'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FACETS_REQUEST = 'app/SearchPage/FACETS_REQUEST';
export const FACETS_SUCCESS = 'app/SearchPage/FACETS_SUCCESS';
export const FACETS_ERROR = 'app/SearchPage/FACETS_ERROR';

export const ALGOLIA_QUERY_REQUEST = 'app/SearchPage/ALGOLIA_QUERY_REQUEST';
export const ALGOLIA_QUERY_SUCCESS = 'app/SearchPage/ALGOLIA_QUERY_SUCCESS';
export const ALGOLIA_QUERY_ERROR = 'app/SearchPage/ALGOLIA_QUERY_ERROR';

export const ALGOLIA_QUERY_LISTINGS_SUCCESS = 'app/SearchPage/ALGOLIA_QUERY_LISTINGS_SUCCESS';
export const ALGOLIA_QUERY_STORES_SUCCESS = 'app/SearchPage/ALGOLIA_QUERY_STORES_SUCCESS';


const ALGOLIA_CLIENT_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY;
const ALGOLIA_LISTINGS_INDEX = process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX;
const ALGOLIA_USERS_INDEX = process.env.REACT_APP_ALGOLIA_USERS_INDEX;

const searchClient = algoliasearch(ALGOLIA_CLIENT_ID, ALGOLIA_API_KEY);
const listingIndex = searchClient.initIndex(ALGOLIA_LISTINGS_INDEX);

function buildAlgoliaFilters(params) {
  const {
    category,
    access_to_rentals,
    Condition_of_the_item,
    Trust_and_verification,
    cancelation_policy,
    reason_for_listing,
    security_deposit,
    priceRange,
  } = params;

  let filtersArray = [];

  // Check each parameter and only add it to filtersArray if it has a value
  if (category) {
    filtersArray.push(`publicData.category:${category}`);
  }

  if (access_to_rentals) {
    filtersArray.push(`publicData.access_to_rentals:${access_to_rentals}`);
  }

  if (Condition_of_the_item) {
    filtersArray.push(`publicData.Condition_of_the_item:${Condition_of_the_item}`);
  }

  if (Trust_and_verification) {
    filtersArray.push(`publicData.Trust_and_verification:${Trust_and_verification}`);
  }

  if (cancelation_policy) {
    filtersArray.push(`publicData.cancelation_policy:${cancelation_policy}`);
  }

  if (reason_for_listing) {
    filtersArray.push(`publicData.reason_for_listing:${reason_for_listing}`);
  }

  if (security_deposit) {
    filtersArray.push(`publicData.security_deposit:${security_deposit}`);
  }

  if(priceRange){
    const [from, to] = priceRange.split(',');
    filtersArray.push(`price.amount >= ${from} AND price.amount <= ${to}`);
  }

  // Join the filters with AND condition for Algolia
  return filtersArray.join(' AND ');
};

function getLabeledFacets(facets) {
  const labeledFacets = {};

  // Loop through each facet category in the facets object
  for (const facetCategory in facets) {
      
    // Remove "publicData." prefix if present
    const facetKey = facetCategory.replace("publicData.", "");

    // Find the correct label mapping for the facet key
    const labelMapping = facetLabelMappings[facetKey];
    
    if (labelMapping) {
      labeledFacets[facetKey] = Object.keys(facets[facetCategory]).map(facetValue => ({
        value: facetValue,
        label: labelMapping[facetValue] || facetValue, // Fallback to the raw value if no label is found
        count: facets[facetCategory][facetValue],
      }));
    } else {
      // If there's no mapping, just return the raw facet values and counts
      labeledFacets[facetKey] = Object.keys(facets[facetCategory]).map(facetValue => ({
        value: facetValue,
        label: facetValue, // Use raw value as label
        count: facets[facetCategory][facetValue],
      }));
    }
  }

  return labeledFacets;
}

const coverEarthRadius = 20037500;

// ================ Reducer ================ //

const initialState = {
  activeListingId:null,
  facets:[],
  facetsInProgress:false,
  facetsError:null,
  algoliaQueryInProgress:false,
  algoliaQueryError:null,
  algoliaListings:[],
  algoliaStores:[],
  nbHitsInsideBounds:0,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case FACETS_REQUEST:
      return {
        ...state, 
        facetsInProgress:true,
        facetsError:null,
      };
    case FACETS_SUCCESS:
      return {
        ...state,
        facetsInProgress:false,
        facets:payload,
      };
    case FACETS_ERROR:
      return {
        ...state,
        facetsInProgress:false,
        facetsError:payload
      };

    case ALGOLIA_QUERY_REQUEST:
      return {
        ...state,
        algoliaQueryInProgress:true,
        algoliaQueryError:null,
      };
    case ALGOLIA_QUERY_SUCCESS:
      return {
        ...state,
        algoliaQueryInProgress:false,
      };
    case ALGOLIA_QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        algoliaListings:payload.listings,
        nbHitsInsideBounds:payload.nbHitsInsideBounds
      };
    case ALGOLIA_QUERY_STORES_SUCCESS:
      return {
        ...state,
        algoliaStores:payload,
      }
    case ALGOLIA_QUERY_ERROR:
      return {
        ...state,
        algoliaQueryInProgress:false,
        algoliaQueryError:payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});


// Algolia facets
export const algoliaFacetsRequest = () => ({
  type:FACETS_REQUEST
});

export const algoliaFacetsSuccess = (facets) => ({
  type:FACETS_SUCCESS,
  payload:facets
});

export const algoliaFacetsError = (err) => ({
  type:FACETS_ERROR,
  payload:err
});


// Algolia Query
// Separate actions
const algoliaQueryListingsSuccess = ({ listings, nbHitsInsideBounds }) => ({
  type: ALGOLIA_QUERY_LISTINGS_SUCCESS,
  payload: { listings, nbHitsInsideBounds },
});

const algoliaQueryStoresSuccess = (stores) => ({
  type: ALGOLIA_QUERY_STORES_SUCCESS,
  payload: stores,
});

export const algoliaQueryRequest = () => ({
  type: ALGOLIA_QUERY_REQUEST
});

export const algoliaQuerySuccess = () => ({
  type: ALGOLIA_QUERY_SUCCESS,
});

export const algoliaQueryError = (err) => ({
  type:ALGOLIA_QUERY_ERROR,
  payload:err
});


export const getFacets = () => (dispatch, getState, sdk) => {
  dispatch(algoliaFacetsRequest());

  return listingIndex.search('', {
    facets: algoliaListingFacets, // Attribute to facet on
  })
    .then(({ facets }) => {
      const labeledFacets = getLabeledFacets(facets);
      dispatch(algoliaFacetsSuccess(labeledFacets));
      return facets;
    })
    .catch(err => {
       dispatch(algoliaFacetsError(err))
    });
};

export const getAlgoliaResults = (params, fetchedEntites) => (dispatch, getState, sdk) => {
  dispatch(algoliaQueryRequest());
  return queryAlgolia(params)
    .then(results => {
      const { listings, stores, nbHitsInsideBounds } = results.data;

      if(fetchedEntites.includes('listings')){
        dispatch(algoliaQueryListingsSuccess({ listings, nbHitsInsideBounds }))
      };
      if(fetchedEntites.includes('stores')){
        dispatch(algoliaQueryStoresSuccess(stores))
      };
      dispatch(algoliaQuerySuccess());
       return results;
    })
    .catch(err => {
      dispatch(algoliaQueryError(err))
    })
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  
  const { exchangeRates } = getState().exchangeRate;
  const { currentUserLocationInfo } = getState().user;
  const { origin: userOrigin } = currentUserLocationInfo || {};
  
  const { lng: userLng, lat: userLat } = userOrigin || {};
  const ottawaLatLng = [45.4215, -75.6972];
  const defaultLatLng = userLng && userLat
    ? [userLat, userLng]
    : ottawaLatLng; // Ottawa's center coordinates

  if (
    typeof window !== 'undefined' &&
    exchangeRates &&
    exchangeRates.length === 0
  ) {
     dispatch(fetchExchangeRates());
  };

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const {
    page = 1,
    keyword = "",
    address,
    bounds,
    origin: latlng,
    category,
    access_to_rentals,
    Condition_of_the_item,
    Trust_and_verification,
    cancelation_policy,
    reason_for_listing,
    security_deposit,
    priceRange
  } = queryParams;

  const aroundLatLngAndRadius =
    latlng && latlng.lat && latlng.lng
      ? { aroundLatLng: `${latlng.lat}, ${latlng.lng}`, aroundRadius: coverEarthRadius }
      : { aroundLatLng: `${defaultLatLng[0]}, ${defaultLatLng[1]}`, aroundRadius: coverEarthRadius };

  function normalizeLongitude(lng) {
    return ((lng + 180) % 360 + 360) % 360 - 180;
  }

  const { ne, sw } = bounds || {};

  const normalizedNELng = ne ? normalizeLongitude(ne.lng) : null;
  const normalizedSWLng = sw ? normalizeLongitude(sw.lng) : null;

  const queryBoundingBox = ne && sw
    ? [ne.lat, normalizedNELng, sw.lat, normalizedSWLng]
    : null;

  const userBoundingBox = userLat && userLng ? createBoundingBox(userLat, userLng, 100) : null;
  const ottawaBoundingBox = createBoundingBox(ottawaLatLng[0], ottawaLatLng[1], 100);

  const insideBoundingBox = queryBoundingBox || userBoundingBox || ottawaBoundingBox;

  const filters = buildAlgoliaFilters({
    category,
    access_to_rentals,
    Condition_of_the_item,
    Trust_and_verification,
    cancelation_policy,
    reason_for_listing,
    security_deposit,
    priceRange
  });

  // Fetch first 24 results (listings and stores)
  const algoliaResultsPromise = dispatch(getAlgoliaResults({
    page,
    query: keyword,
    address,
    aroundLatLngAndRadius,
    filters,
    insideBoundingBox,
    indexes: {
      listingIndex: { indexName: ALGOLIA_LISTINGS_INDEX, minimumHits: 24 }, // Fetch 24 listings first
      storeIndex: { indexName: ALGOLIA_USERS_INDEX, minimumHits: 16 } // Fetch 16 stores first
    }
  }, ['listings', 'stores']));

  const algoliaFacetsPromise = dispatch(getFacets());

  // Store IDs of fetched listings to prevent refetching
  // let fetchedListingIds = [];

  algoliaResultsPromise.then((results) => {
    setTimeout(() => {
      queryAlgolia({
        page,
        query: keyword,
        address,
        aroundLatLngAndRadius,
        filters,
        insideBoundingBox,
        indexes: {
          listingIndex: { indexName: ALGOLIA_LISTINGS_INDEX, minimumHits: 150 }, // Fetch remaining listings
        },
      })
        .then(({ data }) =>
          dispatch(algoliaQueryListingsSuccess({ listings: data.listings, nbHitsInsideBounds: data.nbHitsInsideBounds })))
        .catch(err => dispatch(algoliaQueryError(err)));
    }, 2000)
  });

  return Promise.all([algoliaResultsPromise, algoliaFacetsPromise]);
};

