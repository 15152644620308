import { types as sdkTypes } from './sdkLoader';
import logo from '../assets/logo.png';

const { UUID } = sdkTypes;

const generateAuthor = name => {
  if (name === 'stripe') {
    return {
      id: new UUID('stripe'),
      type: 'user',
      attributes: {
        profile: {
          displayName: 'Stripe',
          abbreviatedName: 'S',
          banned: false,
          deleted: false,
        },
      },
      profileImage: {
        id: new UUID('stripe-image'),
        type: 'image',
        attributes: {
          variants: {
            'square-small2x': {
              height: 480,
              width: 480,
              url:
                'https://res.cloudinary.com/dja5jmxiz/image/upload/w_480,h_480/v1721036149/images_1_tg0gdg.png',
              name: 'square-small2x',
            },
            'square-small': {
              height: 240,
              width: 240,
              url:
                'https://res.cloudinary.com/dja5jmxiz/image/upload/w_240,h_240/v1721036149/images_1_tg0gdg.png',
              name: 'square-small',
            },
          },
        },
      },
    };
  } else if (name === 'sharetribe') {
    return {
      id: new UUID('sharetribe'),
      type: 'user',
      attributes: {
        profile: {
          displayName: 'Rent Anything Store',
          abbreviatedName: 'ST',
          banned: false,
          deleted: false,
        },
      },
      profileImage: {
                id: new UUID('sharetribe-image'),
                type: "image",
                attributes: {
                    variants: {
                        "square-small2x": {
                            "height": 480,
                            "width": 480,
                            "url": "https://i.ibb.co/bQV6FQV/rent-Anythind.jpg",
                            "name": "square-small2x"
                        },
                        "square-small": {
                            "height": 240,
                            "width": 240,
                            "url": "https://i.ibb.co/bQV6FQV/rent-Anythind.jpg",
                            "name": "square-small"
                        }
                    }
                }
            }
    };
  }
};

export function generateAchievementReviews(
  emailVerified,
  stripeConnected,
  publishedListings,
  userName
) {
  const listingMilestone = publishedListings >= 25;
  const reviews = [];

  if (stripeConnected) {
    reviews.push({
      id: new UUID('stripe-connected'),
      type: 'review',
      attributes: {
        type: 'ofProvider',
        rating: 5,
        content: `${
          !!userName?.trim() ? userName : 'This user'
        } has been verified by Stripe, ensuring a highly secure and credible transaction experience. As a trusted partner of RentAnything Store, Stripe's verification adds an extra layer of trust and reliability.`,
        deleted: false,
        state: 'published',
        createdAt: new Date(),
      },
      author: generateAuthor('stripe'),
    });
  }

  if (emailVerified) {
    reviews.push({
      id: new UUID('email-verified'),
      type: 'review',
      attributes: {
        type: 'ofProvider',
        rating: 5,
        content: `${
          !!userName?.trim() ? userName : 'This user'
        } has been authenticated by email, ensuring a secure and trustworthy experience on RentAnything Store.`,
        deleted: false,
        state: 'published',
        createdAt: new Date(),
      },
      author: generateAuthor('sharetribe'),
    });
  }

  if (listingMilestone) {
    reviews.push({
      id: new UUID('listing-milestone'),
      type: 'review',
      attributes: {
        type: 'ofProvider',
        rating: 5,
        content:
          `With over 20 meticulously curated items available for rent, ${!!userName?.trim() ? userName : 'this store'} exemplifies dedication and commitment to the Rent Anything community, providing exceptional value and reliability to all renters.`,
        deleted: false,
        state: 'published',
        createdAt: new Date(),
      },
      author: generateAuthor('sharetribe'),
    });
  }

  return reviews;
}
