import { object, func } from 'prop-types'
import React from 'react'
import { intlShape } from '../../../util/reactIntl'
import css from './PricePackages.module.css'
import { subUnitDivisors } from '../../../config/settingsCurrency';
import { formatMoney } from '../../../util/currency';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

function convertFormat(key) {
    switch (key) {
        case "perDayPrice":
            return "Per day";
        case "perHourPrice":
            return "Per hour";
        case "perMinutePrice":
            return "Per minute";
        case "overnightPrice":
            return "Overnight";
        case "oneWeekPrice":
            return "Per week";
        case "twoDaysPrice":
            return "2 days";
        case "twentyEightDaysPrice":
            return "28 days";
        default:
            return "";
    }
};

export default function PricePackages(props) {
    const { packages, intl, setSelectedPackage, selectedPackage, currentUserCurrency } = props;
    const divisor = subUnitDivisors[currentUserCurrency];

    const selectOptions = Object.keys(packages)
        .map(p =>
        ({
            key: p,
            label: convertFormat(p),
            price: formatMoney(intl, new Money(packages[p].amount, packages[p].currency))
        })
        );


    return (
        <div className={css.packagesContainer}>
            {selectOptions.map((opt) => (
                <div
                    className={
                        selectedPackage === opt.key
                            ? `${css.package} ${css.active}`
                            : css.package
                    }
                    key={opt.key}
                    onClick={() => setSelectedPackage(opt.key)}
                >
                    <p className={css.packageLable}> {opt.label}</p>
                    <p className={css.packagePrice}> {opt.price} </p>
                </div>
            ))}
        </div>
    )
};

PricePackages.prototype = {
    packages: object.isRequired,
    intl: intlShape.isRequired,
    setSelectedPackage: func.isRequired
}
