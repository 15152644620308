import classNames from "classnames";
import React from "react";
import css from './PhoneNumber.module.css'

function PhoneNumber({ phoneNumber, className }) {
  const handlePhoneClick = () => {
    const isConfirmed = window.confirm(`Do you want to call ${phoneNumber}?`);
    if (isConfirmed) {
      window.location.href = `tel:${phoneNumber}`; // Initiate the call
    }
  };

  return (
    <div className={classNames(css.phn, className)}>
      {/* Clickable phone number */}
      <span onClick={handlePhoneClick} style={{ cursor: "pointer"}}>
        {phoneNumber}
      </span>
    </div>
  );
}

export default PhoneNumber;
