import { fetchCartItems } from "../../ducks/cart.duck";
import { fetchExchangeRates } from "../../ducks/exchangeRates.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { transactionLineItems } from "../../util/api";
import { storableError } from "../../util/errors";

export const FETCH_LINE_ITEMS_REQUEST = 'app/CartPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/CartPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/CartPage/FETCH_LINE_ITEMS_ERROR';


const initialState = {
    lineItems: null,
    fetchLineItemsInProgress: false,
    fetchLineItemsError: null,
};

const cartPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_LINE_ITEMS_REQUEST:
            return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
        case FETCH_LINE_ITEMS_SUCCESS:
            return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
        case FETCH_LINE_ITEMS_ERROR:
            return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };
        default:
            return { ...state }
    }
};

export default cartPageReducer;


export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
    type: FETCH_LINE_ITEMS_SUCCESS,
    payload: lineItems,
});
export const fetchLineItemsError = error => ({
    type: FETCH_LINE_ITEMS_ERROR,
    error: true,
    payload: error,
});

export const fetchTransactionLineItems = (fetchLineItemsData) => dispatch => {
    dispatch(fetchLineItemsRequest());

    transactionLineItems(fetchLineItemsData)
        .then(response => {
            const lineItems = response.data;
            dispatch(fetchLineItemsSuccess(lineItems));
        })
        .catch(e => {
            dispatch(fetchLineItemsError(storableError(e)));
            console.error(e, 'fetching-line-items-failed', {
                // listingId: listingId.uuid,
                // orderData,
            });
        });
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
    let { currentUser } = getState().user;
    const { items = {} } = getState().cart;


    if (!currentUser || (currentUser && !currentUser.id)) {
        currentUser = dispatch(fetchCurrentUser());
    }

    // Fetch exchange rates, do not fetch if already exists.
    const { exchangeRates } = getState().exchangeRate;

    if (typeof window != 'undefined' && exchangeRates && exchangeRates.length == 0) {
        dispatch(fetchExchangeRates());
    };


    if (!items || (items && !Object.keys(items).length)) {
        return dispatch(fetchCartItems(config, currentUser));
    };
};