import classNames from 'classnames'
import React from 'react';
import css from './Loader.module.css'

export default function Loader({ className }) {
    return (
        <>
            <div className={classNames(css.loaderContainer, className)}>
                <div className={css.loader}></div>
            </div>
        </>
    )
};
